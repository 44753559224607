import { getSubgraphsRequest } from '@/graphql'
import { IUserInfo, UserInfoInit } from '..'
import { ITotalCount, TotalCountInit } from '@/hooks/useChatHooks'

// 获取总数据统计
export const serverQlTotalCount = async (): Promise<ITotalCount> =>
	new Promise(async (resolve, reject) => {
		let query = `
    query MyQuery {
      aiBoxCounts {
        actualBalance
        addressList
        addressTotal
        crateAt
        efficientAddressTotal
        id
        profitTotalAmount
        rewardPaidAmount
        stakeAmount
        stakeAmountTotal
        updateAt
        withdrawnAmount
        managerWithdrawnAmount
      }
    }
  `

		try {
			const { data } = await getSubgraphsRequest(query)
			if (data?.aiBoxCounts && data.aiBoxCounts.length > 0) {
				resolve(data.aiBoxCounts[0])
			} else {
				resolve(TotalCountInit)
			}
		} catch (error) {
			resolve(TotalCountInit)
		}
	})

// 查询某个人的信息
export const serverQlUserInfo = async (address: string): Promise<IUserInfo> =>
	new Promise(async (resolve, reject) => {
		let query = `
    query MyQuery($id: ID) {
      addressChecks(where: {id: $id}) {
        teamAddressList
        crateAt
        referrer
        rewardPaidAmount
        stakeAmount
        teamAddressTotal
        teamRewardPaidAmount
        teamStakeAmount
        teamWithdrawnAmount
        updateAt
        withdrawnAmount
        stakeAmountTotal
        id
      }
    }
  `

		try {
			const { data } = await getSubgraphsRequest(query, {
				id: address.toLowerCase(),
			})
			if (data?.addressChecks && data.addressChecks.length > 0) {
				resolve(data.addressChecks[0])
			} else {
				resolve(UserInfoInit)
			}
		} catch (error) {
			resolve(UserInfoInit)
		}
	})
