import useSWR from 'swr'
import { DEFAULT_CHAINID } from '~/config/*'
import { multicall } from '@/lib/web3/multicall'
import getChainConfig from '@/lib/web3/getChainConfig'
import { USDT } from '@/contract/abi'
import { formatEther } from 'viem'
import { numFormat2 } from '@/utils'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'

export default function useRootHooks() {
	const currentChainId = DEFAULT_CHAINID
	const { isConnected, address } = useWeb3ModalAccount()

	// 合约信息
	const { contracts } = getChainConfig()
	const USDT_ADDRESS = contracts['USDT_ADDRESS']

	const fetcher = async () => {
		return await multicall(currentChainId, {
			contracts: [
				{
					address: USDT_ADDRESS,
					abi: USDT,
					functionName: 'balanceOf',
					args: [address],
				},
			],
		}).then(res => {
			console.log('res', res[0])
			return {
				usdtAmount: res[0].status === 'success' ? formatEther(res[0].result as bigint) : 0,
			}
		})
	}

	const { data, ...args } = useSWR(isConnected ? `useRootHooks-${address}` : null, fetcher, {
		revalidateIfStale: false,
		revalidateOnFocus: false,
	})

	return {
		data: {
			usdtAmount: (data?.usdtAmount || 0) as number,
			isUsdt: Number(data?.usdtAmount || 0) > 0 ? numFormat2(Number(data?.usdtAmount || 0), 'boollean') : false,
		},
		...args,
	}
}
