import { Modal, ModalBody, ModalContent, useDisclosure } from '@nextui-org/react'
import type { ModalProps } from '@nextui-org/react'
import React, { forwardRef, useImperativeHandle } from 'react'

type Types = {
	placement?: ModalProps['placement']
	bodyClassName?: string
	baseClassName?: string
	centerBaseClass?: string
	children: React.ReactNode
}

function BaseModal(
	{ placement = 'bottom', bodyClassName = '', baseClassName = '', centerBaseClass = '', children }: Types,
	ref: any,
) {
	const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure()

	useImperativeHandle(ref, () => ({
		onOpen,
		onClose,
	}))

	return (
		<Modal
			isOpen={isOpen}
			placement={placement}
			onOpenChange={onOpenChange}
			hideCloseButton
			classNames={{
				base: baseClassName
					? `${baseClassName} bg-[var(--bg)] tail-bg-blur`
					: `tail-bg-blur !m-0 w-full rounded-b-none rounded-t-[1.25rem] border-transparent bg-[var(--bg)] sm:!mb-8 sm:w-96 sm:rounded-b-2xl ${centerBaseClass}`,
			}}
		>
			<ModalContent className="">
				{onClose => (
					<>
						<ModalBody className={`${bodyClassName}`}>{children}</ModalBody>
					</>
				)}
			</ModalContent>
		</Modal>
	)
}

export default forwardRef(BaseModal)
