const menu = {
	'menu.home': '首頁',
	'menu.contract': '合約',
	'menu.chatgpt': 'ChatGPT',
	'menu.option.contract': '選擇權合約',
	'menu.option.ai': 'AI高頻套利',
	'menu.option.qr': '量化機器人',
	'menu.option.ai.mall': 'AI智慧商城',
}

export default menu
