const chat = {
	'chat.input.placeholder1': 'Что вы хотите узнать?',
	'chat.input.placeholder2': 'Ждем завершения ответа...',
	'chat.submit.btn': 'Отправить',
	'chat.submit.on.address': 'Аккаунт не активирован, доступ к чату закрыт',
	'chat.wallet.btn.title': 'Подключите кошелек для общения',
	'chat.data.params.msg1': 'Системная ошибка',
	'chat.data.params.msg2': 'Аккаунт не активирован',
	'chat.data.params.msg3': 'Вы можете отправить до 10 запросов в день',
	'chat.data.params.msg1001': 'Некорректный JSON',
	'chat.data.params.msg.other': 'Запрос не удался, повторите попытку',
}

export default chat
