import menu from './ja/menu'
import home from './ja/home'
import chat from './ja/chat'

const lang = {
	'app.switch.language.tips': '{{msg}}の切り替えに成功しました',
	'app.language': '言語',
	'app.loading': '読み込み中...',
	'app.address.error.tips': '住所エラー',

	'connect.sign': 'サイン...',
	'connect.btn': '接続',
	'connect.logout': 'ログアウト',
	'connect.sign.error': '署名の検証に失敗しました',

	'layout.menu.stake': 'ステーキ',
	'layout.menu.withdraw': '引き出し',
	'layout.menu.team': 'エコロジー',
	'layout.menu.share': '共有',

	...menu,
	...home,
	...chat,
}

export default lang
