const menu = {
	'menu.home': 'Главная',
	'menu.contract': 'Контракты',
	'menu.chatgpt': 'ChatGPT',
	'menu.option.contract': 'Опционный контракт',
	'menu.option.ai': 'AI для арбитража',
	'menu.option.qr': 'Квантовый бот',
	'menu.option.ai.mall': 'Умный AI-магазин',
}

export default menu
