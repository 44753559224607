import { createPublicClient, http, PublicClient } from 'viem'
import { bsc as bscViem } from 'viem/chains'

const bsc = {
	...bscViem,
	rpcUrls: {
		default: {
			http: ['https://binance.llamarpc.com/sk_llama_dddbe777d25ce1577e8f3d7f8351a5ed','https://bsc-dataseed1.binance.org/', 'https://bsc-mainnet.infura.io/v3/87ebef62d75d4bd3a9d832aea2eb1d90'],
		},
	},
}

export const SUPPER_CHAINS = [bsc]

export const SUPPER_NEXTWORK_NAME = {
	[bsc.id]: 'BSC',
}

// Current Supper Chainds
export const CURRENT_SUPPER_CHAINDS = [bsc.id]

// 当前默认CHAINID
export const DEFAULT_CHAINID = CURRENT_SUPPER_CHAINDS[0]

export type ISwitchNetWrok = {
	chainId: `0x${string}`
	chainName: string
	rpcUrls: string[]
	nativeCurrency: any
	blockExplorerUrls: string[]
}

// 当前切换网络需要的参数
export const switchNetwork: {
	[x: number]: ISwitchNetWrok
} = {
	56: {
		chainId: `0x${bsc.id.toString(16)}`,
		chainName: bsc.name,
		rpcUrls: [bsc.rpcUrls.default.http[0]],
		nativeCurrency: bsc.nativeCurrency,
		blockExplorerUrls: ['https://bscscan.com'],
	},
}

export enum ContractName {
	VaultProxy_ADDRESS = 'VaultProxy_ADDRESS',
	USDT_ADDRESS = 'USDT_ADDRESS',
	AUSDT_ADDRESS = 'AUSDT_ADDRESS',
}

type IContractsConfig = {
	[x in ContractName]: `0x${string}`
}
type IOtherConfig = {
	symbol: string
	rpcUrl: string
	explorerUrl: string
}

export type Contracts = IContractsConfig & IOtherConfig

// 合约配置 & 其他信息配置
export const contracts: {
	[x: number]: Contracts
} = {
	56: {
		VaultProxy_ADDRESS: '0xf44ed6e66d598B3c4eac8406f7cb8E9A7a18A562',
		USDT_ADDRESS: '0x55d398326f99059fF775485246999027B3197955',
		AUSDT_ADDRESS: '0xa9251ca9DE909CB71783723713B21E4233fbf1B1',
		symbol: bsc.nativeCurrency.symbol,
		explorerUrl: 'https://bscscan.com',
		rpcUrl: bsc.rpcUrls.default.http[0],
	},
}

export const multicall3: {
	[x: number]: {
		publicClient: PublicClient
		multicallAddress: `0x${string}`
	}
} = {
	56: {
		publicClient: createPublicClient({
			chain: bsc,
			transport: http(),
		}),
		multicallAddress: '0xcA11bde05977b3631167028862bE2a173976CA11',
	},
}
