import { serverQlUserTeamNonZeroAddressTotal } from '@/pages/Count/graphql/total'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import useSWR from 'swr'

export default function useTeamTotal() {
	const { isConnected, address } = useWeb3ModalAccount()

	const { data, ...args } = useSWR(
		isConnected ? `useTeamTotal-${address}` : null,
		async () => {
			return await Promise.all([serverQlUserTeamNonZeroAddressTotal(address || '')]).then(res => {
				const [data] = res
				return {
					teamNonZeroAddressTotal: data.teamNonZeroAddressTotal,
				}
			})
		},
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
		},
	)

	return {
		data: {
			teamNonZeroAddressTotal: data?.teamNonZeroAddressTotal || '0',
		},
		loading: args.isLoading || args.isValidating,
		...args,
	}
}
