const home = {
	'home.usdt.title': 'USDT (стейблкоин)',
	'home.usdt.max': 'Максимум',
	'home.usdt.balance': 'Баланс:',
	'home.usdt.total': 'Общая сумма:',
	'home.total.balance': 'Общий баланс:',
	'home.btn.approve': 'Подтвердить',
	'home.btn.stake': 'Вложить',
	'home.withdraw.title': 'Доступно к выводу:',
	'home.withdraw.2': 'Вывести',
	'home.withdraw.earnings': 'Личный доход:',
	'home.withdraw.amount': 'Доход сети:',
	'home.withdraw.btn1': 'Получить',
	'home.withdraw.btn2': 'Реинвестировать',
	'home.team.title': 'Общий вклад сети',
	'home.team.1': 'Адрес:',
	'home.team.upper': 'Реферальный адрес:',
	'home.team.address.placeholder': 'Введите адрес пользователя',
	'home.team.address.s': 'Адрес привязан',
	'home.team.leavel': 'Уровень:',
	'home.team.bind': 'Привязать',
	'home.invite.title': 'Пригласительная ссылка',
	'home.invite.copy': 'Копировать',
	'home.invite.tip': 'Сначала активируйте аккаунт',
	'home.message.tip0': 'Успешно получено!',
	'home.message.tip1': 'Авторизация выполнена!',
	'home.message.tip2': 'Стейкинг выполнен!',
	'home.message.tip3': 'Минимальная сумма для стейкинга: {{msg}} USDT',
	'home.message.tip4': 'Текущий баланс: {{msg}} USDT',
	'home.message.tip5': 'Скопировано!',
	'home.message.tip6': 'Нельзя привязать собственный адрес',
	'home.message.tip7': 'Адрес успешно привязан!',
	'home.message.tip8': 'Успешно выведено!',
	'home.message.tip9': 'Максимальная сумма для вывода: {{msg}} USDT',
}

export default home
