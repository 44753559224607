const home = {
	'home.usdt.title': 'USDT 스테이블코인',
	'home.usdt.max': '최대',
	'home.usdt.balance': '잔액:',
	'home.usdt.total': '총 입금액:',
	'home.total.balance': '총 잔액:',
	'home.btn.approve': '승인',
	'home.btn.stake': '저장',

	'home.withdraw.title': '출금 가능 여부:',
	'home.withdraw.2': '구원',
	'home.withdraw.earnings': '개인 수입:',
	'home.withdraw.amount': '생태학적 이점:',
	'home.withdraw.btn1': '수신',
	'home.withdraw.btn2': '재입금',

	'home.team.title': '생태학적 총 예금',
	'home.team.1': '주소:',
	'home.team.upper': '추천자:',
	'home.team.address.placeholder': '사용자 주소를 입력하세요',
	'home.team.address.s': '바운드',
	'home.team.leavel': '레벨:',
	'home.team.bind': '바인딩',

	'home.invite.title': '초대 링크',
	'home.invite.copy': '복사',
	'home.invite.tip': '먼저 계정을 활성화하세요',

	'home.message.tip0': '성공적으로 수신되었습니다',
	'home.message.tip1': '인증 성공',
	'home.message.tip2': '입금 성공',
	'home.message.tip3': '최소 입금액: {{msg}} USDT',
	'home.message.tip4': '현재 계정 잔액: {{msg}} USDT',
	'home.message.tip5': '복사 성공',
	'home.message.tip6': '자신의 주소를 바인딩할 수 없습니다',
	'home.message.tip7': '바인딩 성공',
	'home.message.tip8': '사용 성공',
	'home.message.tip9': '현재 계정의 최대 상환 금액: {{msg}} USDT',
}

export default home
