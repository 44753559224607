import { getSubgraphsRequest } from '@/graphql'

// 查询某个人的信息-对应的团队人数质押金额大于1的用户
export const serverQlUserTeamNonZeroAddressTotal = async (
	address: string,
): Promise<{
	teamNonZeroAddressTotal: string
}> =>
	new Promise(async (resolve, reject) => {
		let query = `
    query MyQuery($id: ID) {
      addressChecks(where: {id: $id}) {
        teamNonZeroAddressTotal
      }
    }
  `

		try {
			const { data } = await getSubgraphsRequest(query, {
				id: address.toLowerCase(),
			})
			if (data?.addressChecks && data.addressChecks.length > 0) {
				resolve(data.addressChecks[0])
			} else {
				resolve({
					teamNonZeroAddressTotal: '0',
				})
			}
		} catch (error) {
			resolve({
				teamNonZeroAddressTotal: '0',
			})
		}
	})
