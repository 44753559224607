import { ReactComponent as LANG } from '@/assets/svg/language.svg'

import BaseModal from '@/components/Modal'
import { Button } from '@nextui-org/react'
import { useRef } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

const LangInit: {
	value: 'en' | 'zh' | 'ko' | 'ja' | 'ru'
	label: string
}[] = [
	{ value: 'en', label: 'English' },
	{ value: 'zh', label: '繁体中文' },
	{ value: 'ja', label: '日本語' },
	{ value: 'ko', label: '한국인' },
	{ value: 'ru', label: 'Русский' },
]

export default function LangModule() {
	const BaseModalRef = useRef<any>(null)

	const { i18n, t } = useTranslation()

	const languageChange = (str: string) => i18n.changeLanguage(str)

	const languageChangeSwitch = (str: string) => {
		languageChange(str)

		toast.success(
			t('app.switch.language.tips', {
				msg: str === 'zh' ? '中文' : str === 'ja' ? '日本語' : str === 'ko' ? '한국인' : str === 'ru' ? 'Русский' : 'English',
			}),
			{ id: 'swtich-language', duration: 2000 },
		)
		BaseModalRef.current?.onClose()
	}

	return (
		<div className="">
			<LANG
				className="cursor-pointer"
				onClick={() => {
					BaseModalRef.current?.onOpen()
				}}
			/>
			<BaseModal ref={BaseModalRef} bodyClassName="gap-0 px-10">
				<div className="flex items-center justify-center gap-1 border-b border-[#2D931E] py-4">
					<LANG />
					<span className="text-base font-normal text-white">{t('app.language')}</span>
				</div>
				<div className="flex flex-col gap-2.5 py-5 pb-10">
					{LangInit.map((item, key) => (
						<Button
							onClick={() => languageChangeSwitch(item.value)}
							key={key}
							className={i18n.language === item.value ? 'tail-btn-one' : 'bg-transparent'}
						>
							<span
								className={
									i18n.language === item.value ? 'text-base font-bold text-white' : 'text-base font-normal text-[#9099BC]'
								}
							>
								{item.label}
							</span>
						</Button>
					))}
				</div>
			</BaseModal>
		</div>
	)
}
