const chat = {
	'chat.input.placeholder1': '무엇을 알고 싶으신가요? ',
	'chat.input.placeholder2': '마지막 답변을 기다리는 중',
	'chat.submit.btn': '보내기',
	'chat.submit.on.address': '계정이 활성화되지 않아 채팅할 수 없습니다',
	'chat.wallet.btn.title': '지갑이 연결되어 있지 않아 채팅을 할 수 없습니다',
	'chat.data.params.msg1': '시스템 오류',
	'chat.data.params.msg2': '사용자가 활성화되지 않았습니다',
	'chat.data.params.msg3': '하루에 10번만 접근 가능',
	'chat.data.params.msg1001': '잘못된 json',
	'chat.data.params.msg.other': '요청이 실패했습니다. 다시 보내십시오.',
}

export default chat
