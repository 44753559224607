import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { DEFAULT_CHAINID, SUPPER_CHAINS } from '~/config/*'

// 1. Get projectId
const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || 'cd3b96a7fd285efbdc94936a2f8bf774'

// 3. Create modal
const metadata = {
	name: 'AI Bot',
	description: 'AI Bot Dapp',
	url: 'http://aibot.finance',
	icons: ['http://aibot.finance/favicon.ico'],
}

const chains: {
	chainId: number
	name: string
	currency: string
	explorerUrl: string
	rpcUrl: string
}[] = SUPPER_CHAINS.map(ite => {
	return {
		chainId: ite.id,
		name: ite.name,
		currency: ite.nativeCurrency.symbol,
		explorerUrl: ite.blockExplorers.default.url,
		rpcUrl: ite.rpcUrls.default.http[0],
	}
})

createWeb3Modal({
	ethersConfig: defaultConfig({
		metadata,
		defaultChainId: DEFAULT_CHAINID,
		enableCoinbase: false,
		auth: {
			email: false,
		},
	}),
	chains: chains,
	defaultChain: chains[0],
	projectId,
	themeMode: 'dark',
	themeVariables: {
		'--w3m-border-radius-master': '2px',
	},
	enableAnalytics: true,
	enableSwaps: false,
	allowUnsupportedChain: true,
	allWallets: 'SHOW',
	featuredWalletIds: [
		'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
		'20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66',
		'ef333840daf915aafdc4a004525502d6d49d77bd9c65e0642dbaefb3c2893bef',
	],
})
