// 当前数据对应的等级
export const LeavelValueInit: any = {
	0: '0',
	10: '1',
	20: '2',
	30: '3',
	40: '4',
	50: '5',
	60: '6',
	70: '7',
	80: '8',
	90: '9',
	100: '10',
}
