import useWalletStore from '@/lib/store/useWalletStore'
import ConnectModale from '../Connect'
import LangModule from '../Lang'
import MenuModule from '../Menu'

export default function TopBarModule() {
	const isSign = useWalletStore(state => state.isSign)
	return (
		<div className={`relative ${isSign ? '' : 'z-50'} flex h-16 items-center justify-between px-3 py-4`}>
			<ConnectModale />
			<div className="flex items-center gap-2.5">
				<LangModule />
				<MenuModule />
			</div>
		</div>
	)
}
