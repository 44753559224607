/* eslint-disable */
import React, { useEffect } from 'react'
import { Spinner } from '@nextui-org/react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'

import TOPBG from '@/assets/top-bg.png'

import TopBarModule from '@/components/TopBar'
import useWalletStore from '@/lib/store/useWalletStore'

import { ReactComponent as RETURNSVG } from '@/assets/svg/return.svg'
import ConnectModale from '@/components/Connect'
import { isAddress } from 'viem'
import useHomeHooks from '@/hooks/useHomeHooks'
import useChatHooks from '@/hooks/useChatHooks'
import useRootHooks from '@/hooks/useRootHooks'
import useShareHooks from '@/hooks/useShareHooks'
import { useTranslation } from 'react-i18next'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'

const LinkInit: {
	uri: string
	label: string
}[] = [
	{ uri: '/home', label: 'layout.menu.stake' },
	{ uri: '/withdraw', label: 'layout.menu.withdraw' },
	{ uri: '/team', label: 'layout.menu.team' },
	{ uri: '/share', label: 'layout.menu.share' },
]

export default function LayoutPage() {
	const { t } = useTranslation()

	const location = useLocation()
	const navigate = useNavigate()
	const { isConnected, address } = useWeb3ModalAccount()

	const isSign = useWalletStore(state => state.isSign)

	const { mutate: HomeMutate } = useHomeHooks()
	const { mutate: RootMutate } = useRootHooks()

	const { mutate: ChatMutate } = useChatHooks()
	const { mutate: ShareMutate } = useShareHooks()

	useEffect(() => {
		if (isConnected && isAddress(address ?? '')) {
			HomeMutate()
			RootMutate()
			ShareMutate()
		}
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
	}, [address, isConnected, location.pathname])

	useEffect(() => {
		if (isConnected && isAddress(address ?? '') && location.pathname === '/rqudgaby') ChatMutate()
	}, [isConnected, address, location.pathname])

	if (location.pathname === '/rqudgaby')
		return (
			<div className="tail-layout-count relative flex flex-col !bg-[var(--bg-card)]">
				<ConnectModale isNoPage={true} />
				{isSign && (
					<div className="absolute left-0 top-0 z-[60] flex h-full w-full items-center justify-center bg-white/5 backdrop-blur-[1px] sm:rounded-2xl">
						<Spinner label={t('connect.sign')} labelColor="warning" color="warning"></Spinner>
					</div>
				)}
				<Outlet />
			</div>
		)

	if (location.pathname === '/chat')
		return (
			<div className="cus-layout tail-layout relative flex flex-col !bg-[var(--bg-card)]">
				<ConnectModale isNoPage={true} />
				{isSign && (
					<div className="absolute left-0 top-0 z-[60] flex h-full w-full items-center justify-center bg-white/5 backdrop-blur-[1px] sm:rounded-2xl">
						<Spinner label={t('connect.sign')} labelColor="warning" color="warning"></Spinner>
					</div>
				)}
				<div className="flex h-16 shrink-0 items-center justify-between bg-[#022003] px-2 sm:rounded-t-2xl">
					<RETURNSVG
						className="cursor-pointer"
						onClick={() => {
							navigate('/home', { replace: true })
						}}
					/>
					<div className="flex flex-col items-center justify-center gap-1">
						<span className="text-base font-bold text-white">ChatGTP</span>
						<span className="text-xs text-white">Powered By OpenAI</span>
					</div>
					<div className="h-5 w-5"></div>
				</div>
				<Outlet />
			</div>
		)

	// if (location.pathname === '/option')
	// 	return (
	// 		<div className="tail-layout relative flex flex-col !bg-[var(--bg-card)]">
	// 		<Outlet />
	// 	</div>
	// )

	return (
		<div className="tail-layout relative flex flex-col !bg-[var(--bg-card)]">
			<TopBarModule />
			<div className="absolute left-0 top-0 z-0">
				<img src={TOPBG} alt="" className="h-auto w-full sm:w-96 sm:rounded-t-2xl" />
			</div>
			{isSign && (
				<div className="absolute left-0 top-0 z-[60] flex h-full w-full items-center justify-center bg-white/5 backdrop-blur-[1px] sm:rounded-2xl">
					<Spinner label={t('connect.sign')} labelColor="warning" color="warning"></Spinner>
				</div>
			)}
			<div className="tail-bg-blur tail-bg-layout relative z-20 mt-[10.3125rem] flex h-full flex-col rounded-t-[1.25rem] bg-white/10 sm:mt-[11.25rem] sm:rounded-b-2xl">
				<div className="mb-1 flex h-[3.25rem] shrink-0 justify-between px-7">
					{LinkInit.map((item, key) => (
						<Link
							to={item.uri}
							key={key}
							className={`flex items-center justify-center ${item.uri === location.pathname ? 'tail-link-active' : ''}`}
						>
							<span
								className={`px-2 ${item.uri === location.pathname ? 'text-base font-bold text-white' : 'text-sm font-normal text-[#9099BC]'}`}
							>
								{t(item.label)}
							</span>
						</Link>
					))}
				</div>
				<div className="h-full w-full px-10">
					<Outlet />
				</div>
			</div>
		</div>
	)
}
