const home = {
	'home.usdt.title': 'USDT ステーブルコイン',
	'home.usdt.max': '最大',
	'home.usdt.balance': '残高:',
	'home.usdt.total': '総入金額:',
	'home.total.balance': '合計残高:',
	'home.btn.approve': '承認',
	'home.btn.stake': '保存',

	'home.withdraw.title': '出金可能:',
	'home.withdraw.2': '償還',
	'home.withdraw.earnings': '個人の収入:',
	'home.withdraw.amount': '生態学的利点:',
	'home.withdraw.btn1': '受信',
	'home.withdraw.btn2': '再入金',

	'home.team.title': '生態学的総堆積物',
	'home.team.1': 'アドレス:',
	'home.team.upper': '推奨者:',
	'home.team.address.placeholder': 'ユーザーアドレスを入力してください',
	'home.team.address.s': 'バインド',
	'home.team.leavel': 'レベル:',
	'home.team.bind': 'バインド',

	'home.invite.title': '招待リンク',
	'home.invite.copy': 'コピー',
	'home.invite.tip': '最初にアカウントをアクティブにしてください',

	'home.message.tip0': '正常に受信しました',
	'home.message.tip1': '認証成功',
	'home.message.tip2': '入金成功',
	'home.message.tip3': '最低入金額: {{msg}} USDT',
	'home.message.tip4': '現在のアカウント残高: {{msg}} USDT',
	'home.message.tip5': 'コピーに成功しました',
	'home.message.tip6': '自分のアドレスをバインドできません',
	'home.message.tip7': 'バインド成功',
	'home.message.tip8': '引き換えに成功しました',
	'home.message.tip9': 'アカウントの現在の最大引き換え額: {{msg}} USDT',
}

export default home
